.dialog {
  &::backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(0.5px);
  }
}

.detailsCard {
  width: 25rem;
}

.footer {
  background-color: var(--bottomBar_background_2);
  display: flex;
  justify-content: end;
  column-gap: 1rem;
  padding: 1.5rem 0.75rem;
}
