.checkbox {
  font: var(--font-14-r);
  display: grid;
  grid-template-columns: 1.5rem auto;
  grid-template-areas: "mark text";
  place-items: center;
  overflow: clip;
  grid-column-gap: 0.5rem;
  cursor: pointer;

  &::before {
    content: "";
    overflow: hidden;
    grid-area: mark;
    box-sizing: border-box;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.125rem;
    background-color: var(--checkbox_default_background);
    transition: background-color 150ms ease-in-out;
    transition-delay: 50ms;
  }

  &:has(:checked)::before {
    background-color: var(--checkbox_active_background);
    transition-delay: 0ms;
  }

  &:has(:disabled)::after {
    color: var(--gl_text_secondary_disabled);
    cursor: default;
  }
  &:has(:disabled:checked)::before {
    opacity: 0.5;
  }

  &::after {
    grid-area: text;
    content: attr(data-text);
    color: var(--gl_text_inverse);
  }

  input {
    border: 1px solid var(--gl_dividerBorder_primary);
    overflow: hidden;
    grid-area: mark;
    appearance: none;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    background-color: var(checkbox_default_background);
    clip-path: polygon(50% 0%, 0% 0%, 0% 50%, 0% 100%, 100% 100%, 100% 0%);
    transition:
      clip-path 150ms ease-in-out,
      background-color 150ms ease-in-out,
      border-color 150ms ease-in-out 50ms;
    cursor: pointer;
  }

  input:disabled {
    cursor: default;
    background-color: var(--checkbox_disabled_background);
  }

  input:checked {
    border-color: var(--checkbox_active_background);
    background-color: var(--checkbox_constant_active_content);
    clip-path: polygon(
      39.46% 61.86%,
      24.8% 47.73%,
      19.81% 52.51%,
      39.46% 71.45%,
      81.63% 30.78%,
      76.68% 26.01%
    );
  }
}
