.noteText {
  white-space: pre-wrap;
  font: var(--font-12-r);
  color: var(--gl_text_inverse);
  padding-block: 16px;
  padding-inline: 12px;
}
.footer {
  display: flex;
  justify-content: space-between;
  background-color: var(--bottomBar_background_1);
  border-top: 1px solid var(--gl_dividerBorder_primary);
  padding: 24px 12px;
}

.noNote {
  color: var(--gl_text_constant_secondary_disabled2);
  font: var(--font-16-b);
  padding-block: 1.25rem;
  padding-inline: 6rem;
  display: grid;
  row-gap: 1rem;
  justify-items: center;
  align-content: center;
  text-align: center;
}