.dataSection {
  grid-column: 1/-1;
  .dataList {
    grid-auto-flow: column;
    gap: 12px;
  }
}

.actions {
  grid-column: 1/-1;
}

.close {
  float: inline-end;
  margin-inline: 0.5rem;
  margin-block-start: 0.5rem;
}
