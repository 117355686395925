.calendar {
  display: grid;
  row-gap: 8px;

  .weekdays,
  .days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }

  .weekdays {
    text-align: center;
    li {
      height: 40px;
      min-width: 54px;
      color: var(--gl_text_inverse);
      font: var(--font-12-b);
      align-content: center;
      text-transform: uppercase;
    }
  }

  // создаем каунтер, который отражает нам число месяца
  .days {
    counter-reset: day;
    // перемещаем первый элемент в гриде на нужный день недели (с которого начинается месяц)
    :first-child {
      grid-column: var(--first-weekday);
    }

    .label {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      min-width: 54px;
      width: 100%;
      padding-inline: 20px;
      color: var(--gl_text_inverse);
      font: var(--font-12-r);
      background-color: var(--scheduleSlotBtn_default_background);
      transition:
        color 0.3s,
        background-color 0.3s,
        // 2 секунды на opacity, чтобы не мелькало при переходе от дизейбленной даты
        opacity 2s;
      cursor: pointer;
      &:hover {
        background-color: rgba(
          from var(--scheduleSlotBtn_active_background) r g b / 0.65
        );
      }

      // отображаем число месяца и увеличиваем каунтер на +1
      &::before {
        counter-increment: day;
        content: counter(day);
      }
    }

    // стили в зависимости от состояния
    input {
      &:disabled + .label {
        background-color: var(--scheduleSlotBtn_disabled_background);
        color: var(--gl_text_secondary_disabled);
        cursor: not-allowed;
      }

      &:checked + .label {
        background-color: var(--scheduleSlotBtn_active_background);
        color: var(--gl_text_constant_primary2);
      }

      &:checked:disabled + .label {
        opacity: 0.5;
      }
    }
  }
}
