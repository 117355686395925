:root {
  interpolate-size: allow-keywords;
}

.form {
  padding: 0.75rem;
  display: grid;
  align-content: start;
  row-gap: 0.75rem;
  overflow-y: auto;

  input {
    font: var(--font-12-r);
  }

  .formErrors {
    grid-row: 5;
    grid-column: 1 / -1;
  }
}

summary::-webkit-details-marker {
  display: none;
}

.details {
  overflow: hidden;
  &::details-content {
    block-size: 0%;
    transition:
      block-size 0.2s,
      content-visibility 0.2s;
    transition-behavior: allow-discrete;
  }

  summary {
    display: flex;
    padding-inline: 0.75rem;
    min-height: 1.938rem;
    align-items: center;
    background-color: var(--gl_background_accent_2);
    border-radius: 0.31rem;
    color: var(--gl_text_accent);
    font: var(--font-14-b);
    margin-block-end: 0.75rem;
    &::after {
      content: "";
      display: block;
      width: 1.1rem;
      height: 1.1rem;
      background-color: var(--gl_text_accent);
      clip-path: polygon(
        10% 45%,
        45% 45%,
        45% 10%,
        55% 10%,
        55% 45%,
        90% 45%,
        90% 55%,
        55% 55%,
        55% 90%,
        45% 90%,
        45% 55%,
        10% 55%
      );
      font: var(--font-24-b);
      margin-inline-start: auto;
      transition:
        clip-path 0.2s,
        transform 0.2s;
    }
  }
  &[open] {
    overflow: visible;
    &::details-content {
      block-size: auto;
    }
    > summary::after {
      transform: rotate(360deg);
      clip-path: polygon(
        10% 45%,
        50% 45%,
        50% 45%,
        50% 45%,
        50% 45%,
        90% 45%,
        90% 55%,
        50% 55%,
        50% 55%,
        50% 55%,
        50% 55%,
        10% 55%
      );
    }
  }

  .addNewButton {
    font: var(--font-13-r);
    color: var(--gl_text_inverse);
  }

  .contactSearchBox {
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 0.5rem;
    justify-content: space-between;
    margin-block-end: 0.75rem;
  }

  .newContactFields {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    column-gap: 0.5rem;
    row-gap: 0.75rem;
  }
}

.formFooter {
  display: flex;
  padding: 24px 12px;
  column-gap: 4px;
  justify-content: flex-end;
  background-color: var(--bottomBar_background_1);
}

.selected {
  display: flex;
  background-color: var(--formButton_default_background);
  border: 1px solid var(--gl_dividerBorder_primary);
  color: var(--gl_text_inverse);
  border-radius: 0.188rem;
  width: 100%;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  padding-inline: 1.25rem;
  .phone {
    display: inline-flex;
    column-gap: 0.25rem;
    --icon-color: var(--gl_icon_constant_primary);
    align-items: center;
  }
  .resetButton {
    --icon-color: var(--gl_icon_constant_danger);
    color: var(--gl_icon_constant_danger);
  }
}
