.formContainer {
  display: contents;
  .header {
    grid-row-start: 1;
    grid-column-start: -2;
    background-color: var(--title_background_1);
  }
  .content {
    grid-row-start: 2;
    grid-column-start: -2;
    overflow-y: auto;
    background-color: var(--title_background_1);
  }
}
