.noPrograms {
  display: flex;
  padding-top: 32px;
  padding-inline: 10px;
  justify-content: center;
  text-align: center;
  white-space: pre-line;
  strong {
    margin: 0;
    font: var(--font-24-b);
    color: var(--gl_text_secondary_disabled);
  }
}

.programList {
  display: grid;
  grid-template-columns:
    minmax(10rem, max-content) minmax(22rem, 1fr) minmax(11.25rem, max-content)
    minmax(12.25rem, max-content);
  align-content: start;
  gap: 0.25rem;
  .programListHeader {
    display: grid;
    grid-column: 1 / -1;
    grid-template-columns: subgrid;
    text-transform: uppercase;
    color: var(--gl_text_constant_secondary_disabled2);
    font: var(--font-14-b);
    span {
      padding-inline: 0.75rem;
      padding-block: 1.125rem;
    }
  }

  li {
    display: grid;
    grid-column: 1 / -1;
    grid-template-columns: subgrid;
    article {
      display: contents;
      a {
        display: grid;
        grid-column: 1 / -1;
        grid-template-columns: subgrid;
        border: 1px solid var(--gl_dividerBorder_primary);
        background-color: var(--table_default_background);
        color: var(--gl_text_inverse);
        border-radius: 0.25rem;
        box-shadow:
          0 4px 6px -1px rgb(0 0 0 / 0.1),
          0 2px 4px -2px rgb(0 0 0 / 0.1);
        span {
          display: block;
          padding-inline: 0.75rem;
          padding-block: 1.125rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &.dateRange,
          &.weekdays {
            text-transform: capitalize;
          }
        }
      }
    }
  }
}
