.dialog {
  &::backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: none;
  }
}
.close {
  float: inline-end;
  margin-inline: 0.5rem;
  margin-block-start: 0.5rem;
}
