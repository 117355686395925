@mixin darkTheme() {
  --dodger-blue: #3f98df;
  --header-bg: var(--03dp);
  --selago: var(--03dp);
  --page-bg: var(--01dp);
  --catskill-white: #434343;
  --scheme-bg: var(--page-bg);
  --count-bg: var(--page-bg);
  --selectBackground: var(--06dp);
  --selectColor: #fff;
  --mercury: var(--06dp);
  --periwinkle-gray: var(--06dp);
  --lateDark: #b26bf5;
  --silver-chaliceDark: #ababab;

  //Global colors

  //*Background*
  --gl_overlay_constant: #0000004d;
  --gl_background_1: #292929;
  --gl_background_2: #323232;
  --gl_background_3: #434343;
  --gl_background_gray_1: #434343;
  --gl_background_gray_2: #505050;
  --gl_background_gray_3: #d9d9d9;
  --gl_background_floorplan: #292929;
  --gl_background_accent: #3f98df;
  --gl_background_accent_2: #363636;
  --gl_background_attention: #ca6b00;

  //*Text*
  --gl_text_inverse: #edeff2;
  --gl_text_secondary_2: #d9d9d9;
  --gl_text_secondary_disabled: #ababab;
  --gl_text_inverse2: #313131;
  --gl_text_accent: #3f98df;
  --gl_status_arrived_text: #72e6f7;
  --gl_status_overstayed_text: #f7896f;
  --gl_status_late_text: #f7d694;
  --gl_status_confirmed_text: #60c689;
  --gl_status_notconfirmed_text: #ababab;
  --gl_status_wait_list_text: #cb94ff;
  --gl_status_noshow_text: #f75959;
  --gl_status_cancelled_text: #94cbf7;
  --gl_status_completed_text: #6fe583;
  --gl_status_starters_text: #cb94ff;
  --gl_status_main_text: #fa97fc;
  --gl_status_dessert_text: #ffae52;
  --gl_status_checkdrop_text: #9ee189;
  --gl_status_paid_text: #60c689;
  --gl_status_bus_text: #f8ccff;

  //*Text Constant*
  --gl_text_content_primary: #313131;
  --gl_text_constant_primary2: #ffffff;
  --gl_text_constant_secondary_disabled2: #ababab;
  --gl_text_constant_danger: #cc5962;

  //*Icon*
  --gl_icon_primary_1: #888888;
  --gl_icon_inverse: #e1e3e6;
  --gl_icon_const_inverse: #313131;
  --gl_icon_accent: #3f98df;

  //*Icon Constant*
  --gl_icon_constant_attention: #fe8f35;
  --gl_icon_constant_danger: #cc5962;
  --gl_icon_constant_primary: #ababab;
  --gl_icon_constant_primary2: #ffffff;
  --gl_icon_constant_secondary: #9b9b9b;
  --gl_icon_constant_disabled: #9b9b9bb2;

  //*Border/Divider*
  --gl_dividerBorder_primary: #434343;
  --gl_dividerBorder_primary_2: #545454;
  --gl_dividerBorder_secondary: #3a3a3a;
  --gl_dividerBorder_accent: #3f98df;
  --gl_dividerBorder_hover: #375b77;

  //Control colors

  //*Header Dashboard*
  --header_background: #434343;

  //*Sidebar*
  --sidebar_inactive_background: #434343;
  --sidebar_active_background: #3f98df;

  //*Header Buttons*
  --headerButton_primary_background: #3f98df;
  --headerButton_secondary_background: #505050;

  //*Selects*
  --select_default_background_1: #363636;
  --select_default_background_2: #505050;
  --select_disabled_background: #505050;

  //*Selects menu*
  --selectMenu_default_background_1: #363636;
  --selectMenu_default_background_2: #505050;
  --selectMenu_hover_background: #375b77;
  --selectMenu_focus_background: #3f98df;

  //*Checkbox*
  --checkbox_default_background: transparent;
  --checkbox_active_background: #3f98df;
  --checkbox_disabled_background: #505050;

  //*Checkbox Constant*
  --checkbox_constant_active_content: #ffffff;

  //*Radio button*
  --radioButton_default_background_1: #363636;
  --radioButton_default_background_2: #505050;
  --radioButton_active_background: #3f98df;
  --radioButton_disabled_background: #505050;

  //*Pickers*
  --pickerArrow_default_background_1: #363636;
  --pickerArrow_default_background_2: #505050;

  //*Collapse Button*
  --collapseButton_active_background: #474747;

  //*Legend*
  --legendButton_default_background: #434343;
  --legendButton_active_background: #3f98df;
  --legendModal_background: #434343;

  //*Form button*
  --formButton_default_background: #363636;
  --formButton_active_background: #3f98df;
  --formButton_disabled_background: #505050;

  //*Column Button*
  --columnButton_active_background: #3f98df;
  --columnButton_secondary_background: #505050;

  //*Column Button Constant*
  --columnButton_constant_danger_background: #cc5962;
  --columnButton_constant_status_background: #f4a917;

  //*Scheme Button*
  --shemeButton_default_background: #3f98df;

  //*Button small*
  --buttonSmall_default_background: #3f98df;
  --buttonSmall_disabled_background: #505050;

  //*Button small Constant*
  --buttonSmall_constant_danger_background: #cc5962;

  //*Text button*
  --textButton_default_text: #3f98df;

  //*Zoom button*
  --zoomButton_default_background: #434343;
  --zoomButton_default_content: #ababab;

  //*Date Picker*
  --dtpkr_background_1: #363636;
  --dtpkr_background_2: #505050;
  --dtpkrList_background: #505050;
  --dtpkrListItem_default_background: transparent;
  --dtpkrListItem_hover_background: #375b77;
  --dtpkrListItem_active_background: #3f98df;
  --dtpkr_slot_hover_background: #375b77;
  --dtpkr_slot_active_background: #3f98df;

  //*Title*
  --title_background_1: #292929;
  --title_background_2: #363636;
  --title_background_3: transparent;

  //*Badge constant*
  --badge_constant_background: #e08d8d;
  --badge_constant_content: #ffffff;

  //*Tags Constant*
  --tags_constant_background_1: #f4a917;
  --tags_constant_background_2: #8db972;
  --tags_constant_background_3: #429867;
  --tags_constant_background_4: #589eec;
  --tags_constant_background_5: #ababab;
  --tags_constant_background_6: #888888;
  --tags_constant_background_7: #cc5962;

  //*Status Constant*
  --status_constant_arrived_background: #72e6f7;
  --status_constant_overstayed_background: #f7896f;
  --status_constant_late_background: #f7d694;
  --status_constant_confirmed_background: #60c689;
  --status_constant_notconfirmed_background: #ababab;
  --status_constant_wait_list_background: #cb94ff;
  --status_constant_noshow_background: #f75959;
  --status_constant_cancelled_background: #94cbf7;
  --status_constant_completed_background: #6fe583;
  --status_constant_starters_background: #cb94ff;
  --status_constant_manager_background: #fa97fc;
  --status_constant_dessert_background: #ffae52;
  --status_constant_checkdrop_background: #9ee189;
  --status_constant_paid_background: #60c689;
  --status_constant_bus_background: #f8ccff;

  //*Extrastatus Constant*
  --extraStatus_constant_yellow2_background: #fff59d;

  //*Guests item list*
  --guestsItemlist_default_background: #323232;
  --guestsItemlist_hoverActive_background: #375b77;

  //*Collapse*
  --collapse_waiting_background: #363636;
  --collapse_administrations_default_background: #292929;
  --collapse_administrations_active_background: #292929;

  //*Item row Administrations*
  --itemrow_administrations_background: #323232;

  //*Bottom bar*
  --bottomBar_background_1: #323232;
  --bottomBar_background_2: #434343;
  --bottomBar_background_3: #505050;

  //*Time Slider*
  --timeSliderPrts_text: #ababab;
  --timeSliderPrts_slider_active_background: #3f98df;
  --timeSliderPrts_slider_inactive_background: #505050;
  --timeSliderPrts_timeLablel_background: #3f98df;

  //*Time Slider Constant*
  --timeSliderPrts_constant_knob_background: #ffffff;

  //*Dropdown Button*
  --dropdownButton_acccent_background: #3f98df;
  --dropdownButton_secondary_background: #505050;

  //*Tooltip*
  --tooltip_background: #434343;

  //*Table*
  --table_default_background: transparent;
  --table_gray_background: #434343;
  --table_amaunt_background: #363636;

  //*New card booking*
  --newCardBook_background_1: transparent;
  --newCardBook_background_2: #505050;
  --newCardBook_other_border: #434343;
  --newCardBook_other_border_2: #545454;
  --newCardBook_arrived_background: #384f52;
  --newCardBook_overstayed_background: #5c413b;
  --newCardBook_late_background: #594f3e;
  --newCardBook_confirmed_background: #354d3d;
  --newCardBook_booked_background: #525252;
  --newCardBook_main_background: #593d59;
  --newCardBook_dessert_background: #523c26;
  --newCardBook_other_background: #525252;
  --newCardBook_arrived_border: #3d5659;
  --newCardBook_overstayed_border: #4d3631;
  --newCardBook_late_border: #4d4436;
  --newCardBook_confirmed_border: #364d3e;
  --newCardBook_booked_border: #434343;
  --newCardBook_main_border: #4d344d;
  --newCardBook_dessert_border: #4d3823;
  --newCardBook_arrived_timer: #72e6f7;
  --newCardBook_overstayed_timer: #f7896f;
  --newCardBook_late_timer: #f7d694;
  --newCardBook_confirmed_timer: #60c689;
  --newCardBook_booked_timer: #dcdcdc;
  --newCardBook_main_timer: #fa97fc;
  --newCardBook_dessert_timer: #ffae52;
  --newCardBook_other_timer: #dcdcdc;

  //*Tabs*
  --tabs_inactive_content: #fcfdff;
  --tabs_active_content: #3f98df;
  --tabs_hover_content: #3f98df;
  --tabs_disabled_content: #888888;

  //*Floorplan table*
  --floorplanTable_arrived_background: #62bfcc;
  --floorplanTable_dessert_background: #ffae52;
  --floorplanTable_outstayed_background: #f7896f;
  --floorplanTable_main_background: #fa97fc;
  --floorplanTable_late_background: #ffae52;
  --floorplanTable_empty_background: #ffffff;
  --floorplanTable_confirmed_background: #60c689;
  --floorplanTable_focus_stroke: #ffffff;
  --floorplanTable_text: #292929;
  --floorplanTable_confirmed_future_background: #1e9951;
  --floorplanTable_notconfirmed_background: #bdbdbd;
  --floorplanTable_notconfirmed_future_background: #888888;
  --floorplanTable_manager_background: #fa97fc;

  //*Floorplan legend*
  --floorplanLegend_status_background: #f4a917;
  --floorplanLegend_status_content: #ffffff;
  --floorplanLegend_lock_background: #292929;
  --floorplanLegend_lock_content: #ffffff;
  --floorplanLegend_overbooking_background: #fe8f35;
  --floorplanLegend_overbooking_content: #ffffff;
  --floorplanLegend_deposite_background: #292929;
  --floorplanLegend_deposite_content: #ffffff;

  //*Floorplan number*
  --floorplanNumber_arrived_stroke: #62bfcc;
  --floorplanNumber_dessert_stroke: #f4a917;
  --floorplanNumber_outstayed_stroke: #f7896f;
  --floorplanNumber_main_stroke: #fa97fc;
  --floorplanNumber_late_stroke: #ffae52;
  --floorplanNumber_empty_stroke: #ffffff;
  --floorplanNumber_confirmed_stroke: #60c689;
  --floorplanNumber_default_background: #292929;
  --floorplanNumber_focus_background: #ffffff;
  --floorplanNumber_default_text: #ffffff;
  --floorplanNumber_focus_text: #292929;
  --floorplanNumber_confirmed_future_stroke: #1e9951;
  --floorplanNumber_notconfirmed_stroke: #bdbdbd;
  --floorplanNumber_notconfirmed_future_stroke: #888888;
  // Collapse
  --collapse_list_inactive_background_2: #363636;
  --collapse_list_active_background_2: #434343;

  //*Shifts*
  --shiftsSlot_actualy_default_bg: #375b77;
  --shiftsSlot_old_default_bg: #505050;

  //Grid
  --gridBookingCard_default_background: #505050;

  //Schedule
  --scheduleSlotBtn_default_background: #363636;
  --scheduleSlotBtn_active_background: #3f98df;
  --scheduleSlotBtn_disabled_background: #505050;
}
