.header {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  color: var(--gl_icon_constant_secondary);

  .dateSelect {
    background-color: transparent;
    border: none;
    padding: 0;
    width: 149px;
    justify-content: center;
    column-gap: 4px;
    box-shadow: none;

    // стрелка-указатель в виде треугольника
    &::after {
      content: "";
      width: 10px;
      aspect-ratio: 2 / 1;
      background-color: var(--gl_icon_primary_1);
      clip-path: polygon(0 0, 50% 100%, 100% 0);
      transition: transform 0.3s;
    }
  }
  .dateOptions {
    position: absolute;
    background-color: var(--dtpkr_background_1);
    padding: 0;
    width: 149px;
    scrollbar-gutter: stable;

    // настройка списка: сам список grid, внутренности li и label вставляем в этот grid как его собственных children
    ol {
      display: grid;

      grid-template-columns: 1fr;
      grid-auto-rows: 24px;
      text-align: center;
      // каунтер для отображения года
      counter-reset: year var(--start-year, 0);
      li {
        display: contents;

        // отображение года перед каждым январем и в самом начале списка
        &:first-child,
        &[data-start-of-year] {
          &::before {
            align-content: center;
            font: var(--font-14-b);
            color: var(--gl_text_secondary_disabled);
            content: counter(year);
          }
          // увеличиваем счетчик года на 1
          .dateOption {
            counter-increment: year;
          }
        }
      }
    }

    .dateOption {
      cursor: pointer;
      font: var(--font-14-b);
      color: var(--gl_text_inverse);
      align-content: center;
      &:hover {
        background-color: var(--dtpkrListItem_hover_background);
      }
    }

    input:checked + .dateOption {
      background-color: var(--scheduleSlotBtn_active_background);
      color: var(--gl_text_constant_primary2);
    }
  }

  details[open] {
    .dateSelect {
      // псевдоэлемент на весь экран, чтобы закрыть details пли клике за его пределами
      &::before {
        content: "";
        width: 100dvw;
        height: 100dvh;
        position: fixed;
        top: 0;
        left: 0;
        cursor: auto;
      }
      // вращаем стрелку при открытии
      &::after {
        transform: rotate(180deg);
      }
    }
    // высота открытого списка не превышает 220px
    & + .dateOptions {
      max-height: 220px;
      scrollbar-width: auto;
    }
  }
}
