.noteContainer {
  display: block;
  background-color: var(--extraStatus_constant_yellow2_background);
  grid-area: subheader;
  padding-left: 12px;
  padding-top: 5px;
  padding-bottom: 7px;
  .button {
    display: grid;
    height: auto;
    padding: 0;
    padding-inline: 8px;
    width: 100%;
    border-radius: 0;
    background-color: transparent;
    text-align: start;
    .content {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow-wrap: break-word;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      font: var(--font-12-r);
      color: var(--gl_text_content_primary);
    }
    .title {
      display: block;
      margin: 0;
      padding-bottom: 4px;
      color: var(--gl_background_accent);
      font: var(--font-13-b);
      text-transform: capitalize;
    }

    padding-inline: 8px;
    overflow: hidden;
    border-left: 2px solid var(--gl_background_accent);
  }
}

.emptyNote {
  grid-area: subheader;
}
