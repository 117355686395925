@import "styles/mixins.scss";

.edit {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow: auto;
  background-color: var(--gl_background_1);
}

.editCard {
  padding: 10px;
}

.closeDetails {
  cursor: pointer;
}

.noDetails {
  height: 100%;
  background-color: var(--gl_background_1);
  --msg-font: var(--font-24-b);
  @media (max-width: 1366px) {
    --msg-font: var(--font-16-b);
  }

  display: flex;
  flex-direction: column;
  row-gap: 50px;
  align-items: center;
  -webkit-box-align: center;
  justify-content: center;
  color: var(--cadet-blue);

  p {
    padding-inline: 8%;
    font: var(--msg-font);
    text-align: center;
  }
}
