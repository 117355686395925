.dialog {
  &::backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: none;
  }
}

.dialogContent {
  width: 464px;
}

.closeButton {
  margin-top: 13px;
}

.card {
  display: block;
}

.tabs {
  display: block;
  & > nav {
    display: block;
  }
}
