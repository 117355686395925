.arrow {
  transition: transform 0.3s;
  &.open {
    transform: rotate(180deg);
  }
}

.datalistTop {
  top: 100%;
  z-index: 2;
  transition:
    transform 0.3s,
    opacity 0.3s;
  opacity: 1;
  transform: translateY(0);
  background-color: var(--gl_background_3);
  @starting-style {
    max-height: 21em;
    opacity: 0;
    transform: translateY(1em);
  }
  --icon-color: var(--gl_icon_constant_primary);

}
