.programForm {
  max-width: 25rem;
  display: grid;
  row-gap: 1.5rem;
  align-content: start;
}

.dateSelector {
  display: flex;
  column-gap: 0.75rem;
  align-items: end;
}
.weekdays {
  flex-wrap: nowrap;
  --radio-button-width: auto;

  .weekday {
    text-transform: capitalize;
  }
}

.textareaContainer {
  grid-template-columns: 1fr min-content;
  row-gap: 4px;
  justify-items: flex-start;
  font: var(--font-12-r);
  color: var(--gl_text_inverse);
  .textareaCounter {
    grid-area: auto;
    margin: 0;
  }
  .textarea {
    grid-area: auto;
    grid-column: 1 / -1;
    min-height: 6.5rem;
  }
}

