.datalist {
  position: absolute;
  width: 100%;
  background-color: var(--selectMenu_default_background_1);
  border-radius: 3px;
  display: block;
  max-height: 21em;
  transition: max-height 0.3s;
  border: 0 none;
  overflow-x: hidden;
  margin-top: 8px;
  overflow-y: auto;
  box-shadow: var(--Border_Lightest);
  @starting-style {
    max-height: 0;
  }

  .group {
  }

  .item {
    padding: 8px;
    color: var(--gl_text_inverse);
    font: var(--font-12-r);
    transition:
      color 0.3s,
      background-color 0.3s;

    cursor: pointer;
    &:hover {
      background-color: var(--selectMenu_hover_background);
    }
    &:focus {
      background-color: var(--selectMenu_hover_background);
    }

    &:checked {
      background-color: var(--selectMenu_focus_background);
    }
  }

  .complexItem {
    display: flex;
    justify-content: start;
    width: 100%;
    column-gap: 0.75rem;
    padding-inline: 0.75rem;
    padding-block: 0.5rem;
    min-height: 2.5rem;
    height: auto;
    border-bottom: 1px solid var(--gl_dividerBorder_primary_2);
    &::after {
      content: "";
      margin-inline-start: auto;
      display: block;
      width: 16px;
      height: 16px;
      max-width: 16px;
      max-height: 16px;
      border-radius: 16px;
      border: 1px solid var(--gl_icon_const_inverse);
    }
    &[aria-selected="true"]::after {
      content: "✓";
      font: var(--font-14-b);
      background-color: var(--gl_icon_accent);
      border: none;
      color: var(--gl_icon_constant_primary2);
    }
  }
}
