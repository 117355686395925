.source {
  display: contents;
  .navLinks {
    background-color: var(--title_background_1);
    grid-row-start: 1;
    grid-column-start: -2;
  }
  .content {
    background-color: var(--gl_background_1);
    grid-row-start: 2;
    grid-column-start: -2;
    overflow-y: auto;
    display: grid;
    grid-template-rows: 1fr max-content;
  }
}

.emptySource {
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: -2;
  display: grid;
  align-content: center;
  justify-items: center;
  height: 100%;
  --msg-font: var(--font-24-b);
  @media (max-width: 1366px) {
    --msg-font: var(--font-16-b);
  }
  row-gap: 50px;
  color: var(--cadet-blue);
  p {
    padding-inline: 8%;
    font: var(--msg-font);
    text-align: center;
  }
}
