@mixin colors() {
  --alto: #e0e0e0;
  --alto-2: #cfcfcf;
  --wrf-alto: #313131;
  --athens-gray: #e7eaef;
  --botticelli: #d2daea;
  --catskill-white: #f2f4f8;
  --disabled: #f5f5f5;
  --mercury: #e2e2e2;
  --ghost: #cad0db;
  --heather: #bec5d1;
  --selago: #f1f5fe;
  --periwinkle-gray: #d5dded;
  --tropical-blue: #d2dffa;
  --gray-chateau: #9ea3ae;
  --cadet-blue: #abb0be;
  --silver-chalice: #ababab;
  --dusty-gray: #979797;
  --gray: #888;
  --boulder: #797979;
  --dove-gray: #6c6c6c;
  --dove-gray-2: #676767;
  --tundora: #4b4b4b;
  --tundora-dark: #464646;
  --emperor: #525252;
  --scorpion: #575757;
  --abbey: #484f54;
  --outer-space: #2d3239;
  --mine-shaft: #343434;
  --wrf-white: #ffffff;
  --pastel-green: #44de41;
  --chateau-green: #429867;
  --amazon: #2f754d;
  --dodger-blue: #177ff4;
  --petite-orchid: #e08d8d;
  --orange: #f4a917;
  --hokey-pokey: #d8bc2b;
  --hacienda: #9a861d;
  --saffron: #f1bb30;
  --green: #8db972;
  --blue: #589eec;
  --violet: #b874ed;
  --chestnut-rose: #cc5962;
  --sapphire: #315498;
  --copper-rust: #9a4d4d;
  --gold-6: #faad14;

  --warning: #ff7a45;

  --00dp: #121111;
  --01dp: #292929;
  --02dp: #323232;
  --03dp: #363636;
  --04dp: #3a3a3a;
  --06dp: #434343;
  --08dp: #474747;
  --12dp: #505050;
  --16p: #545454;

  --count-bg: var(--floorplanNumber_default_background);

  //Global colors

  //*Background*
  --gl_overlay_constant: #0000004d;
  --gl_background_1: #ffffff;
  --gl_background_2: #ffffff;
  --gl_background_3: #ffffff;
  --gl_background_gray_1: #ffffff;
  --gl_background_gray_2: #f5f5f5;
  --gl_background_gray_3: #d9d9d9;
  --gl_background_floorplan: #f6eee6;
  --gl_background_accent: #4ba837;
  --gl_background_accent_2: #e7f3e4;
  --gl_background_attention: #fe8f35;

  //*Text*
  --gl_text_inverse: #313131;
  --gl_text_secondary_2: #888888;
  --gl_text_secondary_disabled: #888888;
  --gl_text_inverse2: #ffffff;
  --gl_text_accent: #4ba837;
  --gl_status_arrived_text: #72e6f7;
  --gl_status_overstayed_text: #f7896f;
  --gl_status_late_text: #f7d694;
  --gl_status_confirmed_text: #60c689;
  --gl_status_notconfirmed_text: #ababab;
  --gl_status_wait_list_text: #cb94ff;
  --gl_status_noshow_text: #f75959;
  --gl_status_cancelled_text: #94cbf7;
  --gl_status_completed_text: #6fe583;
  --gl_status_starters_text: #cb94ff;
  --gl_status_main_text: #fa97fc;
  --gl_status_dessert_text: #ffae52;
  --gl_status_checkdrop_text: #9ee189;
  --gl_status_paid_text: #60c689;
  --gl_status_bus_text: #f8ccff;

  //*Text Constant*
  --gl_text_content_primary: #313131;
  --gl_text_constant_primary2: #ffffff;
  --gl_text_constant_secondary_disabled2: #ababab;
  --gl_text_constant_danger: #cc5962;

  //*Icon*
  --gl_icon_primary_1: #ababab;
  --gl_icon_inverse: #313131;
  --gl_icon_const_inverse: #313131;
  --gl_icon_accent: #4ba837;

  //*Icon Constant*
  --gl_icon_constant_attention: #fe8f35;
  --gl_icon_constant_danger: #cc5962;
  --gl_icon_constant_primary: #ababab;
  --gl_icon_constant_primary2: #ffffff;
  --gl_icon_constant_secondary: #9b9b9b;
  --gl_icon_constant_disabled: #9b9b9bb2;

  //*Border/Divider*
  --gl_dividerBorder_primary: #d9d9d9;
  --gl_dividerBorder_primary_2: #d9d9d9;
  --gl_dividerBorder_secondary: #434343;
  --gl_dividerBorder_accent: #4ba837;
  --gl_dividerBorder_hover: #bbd2bc;

  //Control colors

  //*Header Dashboard*
  --header_background: #313131;

  //*Sidebar*
  --sidebar_inactive_background: #313131;
  --sidebar_active_background: #4ba837;

  //*Header Buttons*
  --headerButton_primary_background: #4ba837;
  --headerButton_secondary_background: #ffffff;

  //*Selects*
  --select_default_background_1: #ffffff;
  --select_default_background_2: #ffffff;
  --select_disabled_background: #f5f5f5;

  //*Selects menu*
  --selectMenu_default_background_1: #ffffff;
  --selectMenu_default_background_2: #ffffff;
  --selectMenu_hover_background: #e7f3e4;
  --selectMenu_focus_background: #bbd2bc;

  //*Checkbox*
  --checkbox_default_background: #ffffff;
  --checkbox_active_background: #4ba837;
  --checkbox_disabled_background: #f5f5f5;

  //*Checkbox Constant*
  --checkbox_constant_active_content: #ffffff;

  //*Radio button*
  --radioButton_default_background_1: #ffffff;
  --radioButton_default_background_2: #ffffff;
  --radioButton_active_background: #4ba837;
  --radioButton_disabled_background: #f5f5f5;

  //*Pickers*
  --pickerArrow_default_background_1: #ffffff;
  --pickerArrow_default_background_2: #ffffff;

  //*Collapse Button*
  --collapseButton_active_background: #d5dded;

  //*Legend*
  --legendButton_default_background: #ffffff;
  --legendButton_active_background: #4ba837;
  --legendModal_background: #ffffff;

  //*Form button*
  --formButton_default_background: #ffffff;
  --formButton_active_background: #4ba837;
  --formButton_disabled_background: #f5f5f5;

  //*Column Button*
  --columnButton_active_background: #4ba837;
  --columnButton_secondary_background: #979797;

  //*Column Button Constant*
  --columnButton_constant_danger_background: #cc5962;
  --columnButton_constant_status_background: #f4a917;

  //*Scheme Button*
  --shemeButton_default_background: #4ba837;

  //*Button small*
  --buttonSmall_default_background: #4ba837;
  --buttonSmall_disabled_background: #f5f5f5;

  //*Button small Constant*
  --buttonSmall_constant_danger_background: #cc5962;

  //*Text button*
  --textButton_default_text: #4ba837;

  //*Zoom button*
  --zoomButton_default_background: #ffffff;
  --zoomButton_default_content: #6c6c6c;

  //*Date Picker*
  --dtpkr_background_1: #ffffff;
  --dtpkr_background_2: #ffffff;
  --dtpkrList_background: #ffffff;
  --dtpkrListItem_default_background: #ffffff;
  --dtpkrListItem_hover_background: #e7f3e4;
  --dtpkrListItem_active_background: #bbd2bc;
  --dtpkr_slot_hover_background: #e7f3e4;
  --dtpkr_slot_active_background: #4ba837;

  //*Title*
  --title_background_1: #ffffff;
  --title_background_2: #ffffff;
  --title_background_3: transparent;

  //*Badge constant*
  --badge_constant_background: #e08d8d;
  --badge_constant_content: #ffffff;

  //*Tags Constant*
  --tags_constant_background_1: #f4a917;
  --tags_constant_background_2: #8db972;
  --tags_constant_background_3: #429867;
  --tags_constant_background_4: #589eec;
  --tags_constant_background_5: #ababab;
  --tags_constant_background_6: #888888;
  --tags_constant_background_7: #cc5962;

  //*Status Constant*
  --status_constant_arrived_background: #72e6f7;
  --status_constant_overstayed_background: #f7896f;
  --status_constant_late_background: #f7d694;
  --status_constant_confirmed_background: #60c689;
  --status_constant_notconfirmed_background: #ababab;
  --status_constant_wait_list_background: #cb94ff;
  --status_constant_noshow_background: #f75959;
  --status_constant_cancelled_background: #94cbf7;
  --status_constant_completed_background: #6fe583;
  --status_constant_starters_background: #cb94ff;
  --status_constant_manager_background: #fa97fc;
  --status_constant_dessert_background: #ffae52;
  --status_constant_checkdrop_background: #9ee189;
  --status_constant_paid_background: #60c689;
  --status_constant_bus_background: #f8ccff;

  //*Extrastatus Constant*
  --extraStatus_constant_yellow2_background: #fff59d;

  //*Guests item list*
  --guestsItemlist_default_background: #ffffff;
  --guestsItemlist_hoverActive_background: #e7f3e4;

  //*Collapse*
  --collapse_waiting_background: #e7f3e4;
  --collapse_administrations_default_background: #e7f3e4;
  --collapse_administrations_active_background: #e7f3e4;

  //*Item row Administrations*
  --itemrow_administrations_background: #ffffff;

  //*Bottom bar*
  --bottomBar_background_1: #e7f3e4;
  --bottomBar_background_2: #e7f3e4;
  --bottomBar_background_3: #e7f3e4;

  //*Time Slider*
  --timeSliderPrts_text: #31313180;
  --timeSliderPrts_slider_active_background: #4ba837;
  --timeSliderPrts_slider_inactive_background: #c3ccc0;
  --timeSliderPrts_timeLablel_background: #4ba837;

  //*Time Slider Constant*
  --timeSliderPrts_constant_knob_background: #ffffff;

  //*Dropdown Button*
  --dropdownButton_acccent_background: #4ba837;
  --dropdownButton_secondary_background: #979797;

  //*Tooltip*
  --tooltip_background: #3a3a3a;

  //*Table*
  --table_default_background: transparent;
  --table_gray_background: #fbfbfb;
  --table_amaunt_background: #e7f3e4;

  //*New card booking*
  --newCardBook_background_1: #ffffff;
  --newCardBook_background_2: #ffffff;
  --newCardBook_other_border: #d9d9d9;
  --newCardBook_other_border_2: #d9d9d9;
  --newCardBook_arrived_background: #e3fafd;
  --newCardBook_overstayed_background: #f6e9e6;
  --newCardBook_late_background: #fcefdc;
  --newCardBook_confirmed_background: #e7f3e4;
  --newCardBook_booked_background: #f5f5f5;
  --newCardBook_main_background: #ffdff6;
  --newCardBook_dessert_background: #ffe9d4;
  --newCardBook_other_background: #f5f5f5;
  --newCardBook_arrived_border: #00d3f2;
  --newCardBook_overstayed_border: #f7896f;
  --newCardBook_late_border: #d8bc2b;
  --newCardBook_confirmed_border: #60c689;
  --newCardBook_booked_border: #d9d9d9;
  --newCardBook_main_border: #fa97fc;
  --newCardBook_dessert_border: #ffae52;
  --newCardBook_arrived_timer: #009cb2;
  --newCardBook_overstayed_timer: #cc5962;
  --newCardBook_late_timer: #997a00;
  --newCardBook_confirmed_timer: #00992b;
  --newCardBook_booked_timer: #313131;
  --newCardBook_main_timer: #cc3dbe;
  --newCardBook_dessert_timer: #a67135;
  --newCardBook_other_timer: #313131;

  //*Tabs*
  --tabs_inactive_content: #313131;
  --tabs_active_content: #4ba837;
  --tabs_hover_content: #4ba837;
  --tabs_disabled_content: #888888;

  //*Floorplan table*
  --floorplanTable_arrived_background: #62bfcc;
  --floorplanTable_dessert_background: #ffae52;
  --floorplanTable_outstayed_background: #f7896f;
  --floorplanTable_main_background: #fa97fc;
  --floorplanTable_late_background: #ffae52;
  --floorplanTable_empty_background: #ffffff;
  --floorplanTable_confirmed_background: #60c689;
  --floorplanTable_focus_stroke: #292929;
  --floorplanTable_text: #292929;
  --floorplanTable_manager_background: #fa97fc;

  //*Floorplan legend*
  --floorplanLegend_status_background: #f4a917;
  --floorplanLegend_status_content: #ffffff;
  --floorplanLegend_lock_background: #ffffff;
  --floorplanLegend_lock_content: #292929;
  --floorplanLegend_overbooking_background: #fe8f35;
  --floorplanLegend_overbooking_content: #ffffff;
  --floorplanLegend_deposite_background: #ffffff;
  --floorplanLegend_deposite_content: #292929;

  //*Floorplan number*
  --floorplanNumber_arrived_stroke: #62bfcc;
  --floorplanNumber_dessert_stroke: #f4a917;
  --floorplanNumber_outstayed_stroke: #f7896f;
  --floorplanNumber_main_stroke: #fa97fc;
  --floorplanNumber_late_stroke: #ffae52;
  --floorplanNumber_empty_stroke: #ffffff;
  --floorplanNumber_confirmed_stroke: #60c689;
  --floorplanNumber_default_background: #f3e8dd;
  --floorplanTable_notconfirmed_background: #bdbdbd;
  --floorplanNumber_focus_background: #292929;
  --floorplanNumber_default_text: #292929;
  --floorplanNumber_focus_text: #ffffff;
  --floorplanNumber_confirmed_future_stroke: #1e9951;
  --floorplanNumber_notconfirmed_stroke: #bdbdbd;
  --floorplanNumber_notconfirmed_future_stroke: #ababab;

  // Collapse
  --collapse_list_inactive_background_2: #e7f3e4;
  --collapse_list_active_background_2: #e7f3e4;

  //*Shifts*
  --shiftsSlot_actualy_default_bg: #bbd2bc;
  --shiftsSlot_old_default_bg: #e2e2e2;

  //Grid
  --gridBookingCard_default_background: #e2e2e2;

  //Schedule
  --scheduleSlotBtn_default_background: #e7f3e4;
  --scheduleSlotBtn_active_background: #4ba837;
  --scheduleSlotBtn_disabled_background: #f5f5f5;
}
