.arrow {
  transition: transform 0.3s;
  &.open {
    transform: rotate(180deg);
  }
}

.datalistTop {
  top: 100%;
  z-index: 2;
  transition:
    transform 0.3s,
    opacity 0.3s;
  opacity: 1;
  transform: translateY(0);
  @starting-style {
    max-height: 21em;
    opacity: 0;
    transform: translateY(1em);
  }
   --icon-color: var(--gl_icon_constant_primary);
}

.dialog {
  margin: 0;
  position: absolute;
  top: 60px;
  left: var(--aside-width);
  width: auto;
  height: calc(100% - 60px);
  overflow-y: hidden;
  &::backdrop {
    display: none;
  }
  ul {
    min-height: auto;
  }
}

.dialogContent {
  height: 100%;
}

.sourceButtons {
  display: flex;
  background-color: var(--formButton_default_background);
  border: 1px solid var(--gl_dividerBorder_primary);
  border-radius: 0.188rem;
  width: 100%;
  height: 31px;

  justify-content: space-between;
  & > button {
    background-color: var(--formButton_default_background);
    padding: 0.75rem;
    height: 100%;
    border: none;
    font: var(--font-12-r);
    &:disabled {
      pointer-events: none;
    }
  }
  .chooseButton {
    flex: 1;
    display: inline-flex;
    justify-content: start;
    color: var(--gl_text_inverse);
    column-gap: 0.75rem;
    * {
      display: inline-flex;
      height: 100%;
      align-items: center;
    }

    &.withPlaceholder {
      justify-content: center;
      &::before {
        content: "+ ";
        font: var(--font-16-b);
      }
    }
  }

  .resetButton {
    --icon-color: var(--gl_icon_constant_danger);
    color: var(--gl_icon_constant_danger);
  }
}
