.textareaContainer {
  grid-template-columns: 1fr min-content;
  row-gap: 4px;
  justify-items: flex-start;
  font: var(--font-12-r);
  color: var(--gl_text_inverse);
  text-transform: capitalize;
  .textareaCounter {
    grid-area: auto;
    margin: 0;
  }
  .textarea {
    grid-area: auto;
    grid-column: 1 / -1;
    min-height: 124px;
  }
}

.footer {
  display: flex;
  justify-content: flex-start;
  column-gap: 4px;
  background-color: var(--bottomBar_background_1);
  border-top: 1px solid var(--gl_dividerBorder_primary);
  padding: 24px 12px;
}
